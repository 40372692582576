<template>
  <div class="article_list f_14">
    <m-nav ref="nav" :page="'ArticleList'" :detailUrl="urlName"></m-nav>
    <div class="books">
      <div class="text_title f_16"><h2>Novel info</h2></div>
      <h3 class="books_t">
        {{ content.novel_name }}
      </h3>
      <div class="books_desc">
        <img :src="content.novel_cover" :alt="content.novel_name" />
      </div>
      <div class="books_info f_13">
        <p>
          <span class="f_w">Author: </span>
          <span
            class="hover_c"
            @click="goTypeList(content.novel_author, 3, null)"
            >{{ content.novel_author }}</span
          >
        </p>
        <p>
          <span class="f_w">Alternative names: </span>
          <span>{{ content.novel_name }}</span>
        </p>
        <p>
          <span class="f_w">Genre: </span>
          <span
            class="hover_c"
            v-for="childItem in content.tag"
            :key="childItem.id"
            @click="goTypeList(childItem.id, 2, childItem.name)"
          >
            {{ childItem.name }},
          </span>
        </p>
        <p>
          <span class="f_w">Status: </span>
          <span
            style="text-transform: capitalize"
            class="hover_c"
            @click="goTypeList(content.novel_status, 4, null)"
            >{{ content.novel_status }}</span
          >
        </p>
      </div>
      <div
        class="books_con p_15"
        :class="{ max_h: showHeight }"
        v-html="content.novel_info"
      ></div>
      <div class="books_btn p_15" v-if="!showHeight">
        <p @click="showHeight = true">See more »</p>
      </div>
      <div class="chapter p_15">
        <div class="chapter_t"><h3 class="f_15">Novel info</h3></div>
        <div class="chapter_c">
          <p
            v-for="(item, index) in newChapterList"
            :key="index"
            @click="goChapter(item.novel_id, item.id, item.name)"
          >
            {{ item.name }}
          </p>
        </div>
      </div>
      <div class="chapter">
        <div class="chapter_t f_16"><h2>Chapter List</h2></div>
        <div class="chapter_c">
          <p
            v-for="(item, index) in chapterList"
            :key="index"
            @click="goChapter(item.novel_id, item.id, item.name)"
          >
            {{ item.name }}
          </p>
        </div>
      </div>
      <div class="page_box" v-if="total && total > 0">
        <div
          class="first"
          :class="{ active_white: currentPage == 1 }"
          @click="goFirst"
        >
          « First
        </div>
        <van-pagination
          v-model="currentPage"
          :total-items="total"
          :items-per-page="pageNum"
          :show-page-size="pageSize"
          class="page_class"
          @change="changePage(currentPage)"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
        </van-pagination>
        <div
          class="end"
          :class="{ active_white: currentPage == endPage }"
          @click="goEnd"
        >
          end »
        </div>
      </div>
      <div class="page-nav" v-if="total && total > 0">
        <div
          class="page-btn"
          @click="pageShow = !pageShow"
          :class="{ active_b: pageShow }"
        >
          Select page<span class="caret"></span>
        </div>
        <div class="input-group" v-if="pageShow">
          <input
            class="form-control t_class"
            name="page"
            type="number"
            placeholder="Page..."
            v-model="pageValue"
            @keyup.enter="goPage(pageValue)"
          />
          <span class="input-group-btn t_class" @click="goPage(pageValue)"
            >Go</span
          >
        </div>
      </div>
    </div>
    <new-footer-m></new-footer-m>
  </div>
</template>

<script>
import { fictionDetail, fictionChapterList } from "@/api/index.js";
import MNav from "@/components/M/MNav.vue";
import NewFooterM from "@/components/M/NewFooterM.vue";
export default {
  components: { MNav, NewFooterM },
  name: "ArticleList",
  data() {
    return {
      showHeight: false, //更多
      content: {}, //小说内容
      chapterList: [], //章节列表
      newChapterList: [], //最新章节列表
      currentPage: 1, //当前页
      pageSize: 7, //page页面数码展示
      total: null, //总数量
      pageNum: 50, //一页显示数量
      endPage: null, //最后一页
      pageShow: false, //页码跳转显示
      novel_id: null, //小说id
      pageValue: null, //跳转页面到
      urlName: {}, //路由名称
      title1: "",
    };
  },
  metaInfo() {
    return {
      title: this.content.novel_name + " | Webnovelsite",
      meta: [
        {
          name: "title",
          content: this.content.novel_name + " | Webnovelsite",
        },
        {
          name: "description",
          content: `${this.title1} | ${this.content.novel_name} | From Webnovelsite`,
        },
        {
          name: "keywords",
          content: `${this.content.novel_name},${this.content.novel_name} + free novel, ${this.content.novel_name} + latest chapter,  ${this.content.novel_status},author + ${this.content.novel_author},Webnovelsite`,
        },
      ],
    };
  },
  created() {
    this.novel_id = this.$route.params.id;
    this.getFictionDetail();
    this.getNewChapterList();
    this.getFictionChapterList();
  },
  methods: {
    // 获取小说详情
    getFictionDetail() {
      fictionDetail(this.novel_id).then((res) => {
        if (res.code == 200) {
          this.content = res.data;
          this.title1 = this.content.novel_info.split("</p>")[0].slice(3);
          this.urlName = res.data.tag[0];
        }
      });
    },
    //最新章节列表
    getNewChapterList() {
      fictionChapterList(1, -1, this.novel_id).then((res) => {
        if (res.code == 200) {
          this.newChapterList = res.data.data;
        }
      });
    },
    // 获取小说章节列表接口
    getFictionChapterList() {
      fictionChapterList(this.currentPage, 50, this.novel_id).then((res) => {
        if (res.code == 200) {
          this.chapterList = res.data.data;
          this.total = res.data.total || 0;
          this.endPage = this.total > 0 ? Math.ceil(this.total / 50) : 0;
          // this.endPage = res.data.last_page;
        }
      });
    },
    // 去小说章节
    goChapter(nid, cid, name) {
      let arrayLocal = JSON.parse(localStorage.getItem("historyList"));
      if (arrayLocal) {
        let isTrue = arrayLocal.some((item) => item.novel_id == nid);
        if (isTrue) {
          arrayLocal.forEach((item) => {
            if (item.novel_id == nid) {
              item.last_chapter_name = name;
              item.chapter_id = cid;
            }
          });
        } else {
          arrayLocal.unshift({
            novel_id: nid,
            novel_name: this.content.novel_name,
            last_chapter_name: name,
            chapter_id: cid,
          });
        }
      } else {
        arrayLocal = [];
        arrayLocal.unshift({
          novel_id: nid,
          novel_name: this.content.novel_name,
          last_chapter_name: name,
          chapter_id: cid,
        });
      }

      localStorage.setItem("historyList", JSON.stringify(arrayLocal));
      this.$router.push({
        path: `/article/${nid}/${cid}`,
      });
    },
    // 去分类列表
    goTypeList(key, type, name) {
      if (type == 3) {
        key = key.replace(/\s/g, "-");
        this.$router.push({ path: `/author/${key}` });
      }
      if (type == 2) {
        name = name.replace(/\s/g, "-");
        this.$router.push({
          path: `/genre/${key}`,
          query: { type: name },
        });
      }
      if (type == 4) {
        this.$router.push({ path: `/status/${key}` });
      }
      // console.log("去分类列表", key, type,name);
    },
    // 去首页
    goFirst() {
      this.currentPage = 1;
      this.getFictionChapterList();
    },
    // 去最后一页
    goEnd() {
      this.currentPage = this.endPage;
      this.getFictionChapterList();
    },
    // 切换页码
    changePage(value) {
      if (value == 1) {
        this.goFirst();
      } else if (value == this.endPage) {
        this.goEnd();
      } else {
        this.currentPage = value;
        this.getFictionChapterList();
      }
    },
    // 跳转到第几页
    goPage(value) {
      if (Number(value) >= this.endPage) {
        this.goEnd();
      }
      if (Number(value) <= 1) {
        this.goFirst();
      }
      if (Number(value) > 1 && Number(value) < this.endPage) {
        this.currentPage = Number(value);
        this.getFictionChapterList();
      }
      this.pageValue = "";
      this.pageShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.article_list {
  .f_w {
    font-weight: 600;
  }
  .books {
    .text_title {
      height: 40px;
      text-transform: uppercase;
      border-bottom: 1px solid #ccc;
      position: relative;
      margin-bottom: 15px;

      h2 {
        padding-left: 5px;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #4e4e4e;
      }
    }

    .books_t {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
      padding: 0 15px 7px;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .books_desc {
      img {
        margin: 0 auto;
        display: block;
        height: 324px;
        width: 221px;
      }
    }

    .books_info {
      padding: 15px 15px 0;

      p {
        margin-bottom: 5px;
      }
    }

    .books_con {
      margin-top: 10px;
      max-height: 200px;
      overflow: hidden;

      &::v-deep p {
        margin-bottom: 20px;
      }
    }
    .books_btn {
      text-align: right;
      background-color: #f4f4f4;

      p {
        cursor: pointer;
        margin-top: 5px;
        display: inline-block;
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
        border: 1px solid #ccc;
      }
    }
    .max_h {
      max-height: 2000px;
    }

    .p_15 {
      padding: 0 15px;
    }
    .chapter {
      .chapter_t {
        font-size: 16px;
        text-transform: uppercase;
        font-family: "Roboto Condensed";
        height: 30px;
        width: 100%;
        margin: 15px 0 10px;
        border-bottom: 1px solid #ccc;

        h2,
        h3 {
          padding: 0 5px;
          height: 30px;
          line-height: 30px;
          display: inline-block;
          border-bottom: 1px solid #4e4e4e;
        }
      }

      .chapter_c {
        p {
          padding: 5px 15px 5px 30px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .page_box {
      display: flex;
      margin: 20px 0 38px;
      justify-content: center;

      .first {
        line-height: 20px;
        border: 1px solid #ddd;
        border-right: none;
        padding: 4px 10px;
      }

      .end {
        line-height: 20px;
        border: 1px solid #ddd;
        padding: 4px 10px;
        border-left: none;
      }

      .page_class {
        font-size: 14px;
        &::v-deep .van-pagination__item {
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          height: 32px;
          width: 27px;
          padding: 5px 10px;
          color: #4e4e4e;
          background: #f2f2f2;
        }

        &::v-deep .van-pagination__item--active {
          background-color: #34495e;
          border-top: #34495e;
          border-bottom: #34495e;
          color: #fff;
        }
        &::v-deep .van-pagination__item--disabled,
        &::v-deep .van-pagination__item--disabled:active {
          opacity: 1;
          background: #ffffff !important;
        }

        &::v-deep .van-pagination__page {
          flex-grow: 1;
        }
      }

      .active_white {
        background-color: #fff;
      }
    }

    .page-nav {
      text-align: center;
      position: relative;

      .page-btn {
        padding: 5px 10px;
        display: inline-block;
        border: 1px solid #ddd;

        .caret {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 4px;
          vertical-align: middle;
          border-bottom: 4px dashed;
          border-bottom: 4px solid \9;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
        }
      }

      .input-group {
        position: absolute;
        top: -36px;
        right: calc(50% - 50px);
        display: table;
        box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
        border-radius: 4px;

        .t_class {
          height: 34px;
          padding: 6px 12px;
          color: #555;
          border: 1px solid #ccc;
          border-radius: 4px;
          display: table-cell;
          box-sizing: border-box;
        }

        .form-control {
          max-width: 117px;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }

        .input-group-btn {
          border-left: none;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }

      .active_b {
        background-color: #34495e;
        border-color: #34495e;
        color: #fff;
      }
    }
  }
}
</style>
