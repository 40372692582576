<template>
  <div class="article_list" @click.stop="closeSelect">
    <new-nav
      ref="nav"
      :page="'ArticleList'"
      :detailUrl="urlName"
      :typeList="typeList"
    ></new-nav>
    <div class="container content">
      <!-- 左侧 -->
      <div class="content_l">
        <div class="text_title"><h2>Novel info</h2></div>
        <!-- 书籍信息 -->
        <div class="books">
          <div class="books_l">
            <div class="books_desc">
              <img :src="content.novel_cover" :alt="content.novel_name" />
            </div>
            <div class="books_info f_13">
              <p>
                <span class="f_w">Author:</span>
                <span
                  class="hover_c"
                  @click="goTypeList(content.novel_author, 3, null)"
                  >{{ content.novel_author }}</span
                >
              </p>
              <p>
                <span class="f_w">Alternative names:</span>
                <span>{{ content.novel_name }}</span>
              </p>
              <p>
                <span class="f_w">Genre:</span>
                <span
                  class="hover_c"
                  v-for="childItem in content.tag"
                  :key="childItem.id"
                  @click="goTypeList(childItem.id, 2, childItem.name)"
                >
                  {{ childItem.name }},
                </span>
              </p>
              <p>
                <span class="f_w">Status:</span>
                <span
                  style="text-transform: capitalize"
                  class="hover_c"
                  @click="goTypeList(content.novel_status, 4, null)"
                  >{{ content.novel_status }}</span
                >
              </p>
            </div>
          </div>
          <div class="books_r">
            <h2 class="books_r_t f_24">{{ content.novel_name }}</h2>
            <div
              class="books_con f_12"
              :class="{ max_h: showHeight }"
              v-html="content.novel_info"
            ></div>
            <div class="books_btn" v-if="!showHeight">
              <p @click="showHeight = true" class="f_12">See more »</p>
            </div>
            <div class="chapter">
              <div class="chapter_t f_16"><h3>Latest chapters</h3></div>
              <div class="chapter_c">
                <p
                  v-for="(item, index) in newChapterList"
                  :key="index"
                  @click="goChapter(item.novel_id, item.id, item.name)"
                >
                  {{ item.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 章节列表 -->
        <div class="chapter">
          <div class="chapter_t f_20"><h2>Chapter List</h2></div>
          <div class="chapter_c chapter_list">
            <div
              class="chapter_item"
              v-for="(item, index) in chapterList"
              :key="index"
              @click="goChapter(item.novel_id, item.id, item.name)"
            >
              {{ item.name }}
            </div>
          </div>
          <!-- 页码 -->
          <div class="page_box" v-if="total && total > 0">
            <div
              class="first"
              :class="{ active_white: currentPage == 1 }"
              @click="goFirst"
            >
              « First
            </div>
            <van-pagination
              v-model="currentPage"
              :total-items="total"
              :items-per-page="pageNum"
              :show-page-size="pageSize"
              class="page_class"
              @change="changePage(currentPage)"
            >
              <template #prev-text>
                <van-icon name="arrow-left" />
              </template>
              <template #next-text>
                <van-icon name="arrow" />
              </template>
            </van-pagination>
            <div
              class="end"
              :class="{ active_white: currentPage == endPage }"
              @click="goEnd"
            >
              List »
            </div>
            <div class="page-nav">
              <div
                class="page-btn"
                @click="pageShow = !pageShow"
                :class="{ active_b: pageShow }"
              >
                Select page<span class="caret"></span>
              </div>
              <div class="input-group" v-if="pageShow">
                <input
                  class="form-control t_class"
                  type="number"
                  placeholder="Page..."
                  v-model="pageValue"
                  @keyup.enter="goPage(pageValue)"
                />
                <span class="input-group-btn t_class" @click="goPage(pageValue)"
                  >Go</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--右侧 -->
      <div class="content_r">
        <list-template
          v-if="authorMoreList && authorMoreList.length > 0"
          :type="'two'"
          :borderShow="true"
          :page="'ArticleList'"
          :searchValue="'More From Author'"
          :data="authorMoreList"
          class="m_b"
          @onChapterList="goChapterList"
        ></list-template>
        <list-template
          :type="'three'"
          :searchValue="'Hot Harem Novel'"
          :page="'ArticleList'"
          :data="hotList"
          @onTypeList="goTypeList"
          @onChapterList="goChapterList"
        ></list-template>
      </div>
    </div>
    <new-footer></new-footer>
  </div>
</template>

<script>
import {
  fictionDetail,
  fictionChapterList,
  fictionHot,
  fictionSearch,
  fictionLabel,
} from "@/api/index.js";
import NewNav from "@/components/pc/NewNav.vue";
import NewFooter from "@/components/pc/NewFooter.vue";
import ListTemplate from "@/components/pc/ListTemplate.vue";

export default {
  components: {
    NewNav,
    NewFooter,
    ListTemplate,
  },
  name: "ArticleList",
  inject: ["reload"],
  data() {
    return {
      showHeight: false,
      authorMoreList: [], //作者更多作品
      content: {}, //小说内容
      hotList: [], //热门小说
      chapterList: [], //章节列表
      newChapterList: [], //最新章节列表
      currentPage: 1, //当前页
      endPage: null, //最后一页
      pageSize: 7, //页码数量显示
      total: null, //总页数
      pageNum: 50, //一页显示数量
      pageShow: false, //页码跳转显示
      novel_id: null, //小说id
      pageValue: null, //跳转页面到
      typeList: [], //小说类型
      urlName: {}, //路由名称
      title1: "",
    };
  },
  metaInfo() {
    return {
      title: this.content.novel_name + " | Webnovelsite",
      meta: [
        {
          name: "title",
          content: this.content.novel_name + " | Webnovelsite",
        },
        {
          name: "description",
          content: `${this.title1} | ${this.content.novel_name} | From Webnovelsite`,
        },
        {
          name: "keywords",
          content: `${this.content.novel_name},${this.content.novel_name} + free novel, ${this.content.novel_name} + latest chapter,  ${this.content.novel_status},author + ${this.content.novel_author},Webnovelsite`,
        },
      ],
    };
  },
  created() {
    this.novel_id = this.$route.params.id;
    this.getFictionDetail();
    this.getNewChapterList();
    this.getFictionChapterList();
    this.getHotList();
    let typeList = this.$local.cacheGet("typeList");
    if (!typeList) {
      this.getTypeList();
    } else {
      this.typeList = typeList;
    }
  },
  methods: {
    // 小说类型
    getTypeList() {
      fictionLabel().then((res) => {
        if (res.code == 200) {
          this.typeList = res.data;
          let time = 24 * 60 * 60;
          this.$local.cacheSet("typeList", res.data, time);
        }
      });
    },
    // 获取小说详情
    getFictionDetail() {
      fictionDetail(this.novel_id).then((res) => {
        if (res.code == 200) {
          this.content = res.data;
          this.title1 = this.content.novel_info.split("</p>")[0].slice(3);
          this.urlName = res.data.tag[0];
          this.getMoreAuthor(res.data.novel_author, res.data.novel_id);
        }
      });
    },
    // 获取更多作者作品
    getMoreAuthor(value, id) {
      fictionSearch(1, 10, 3, value).then((res) => {
        if (res.code == 200) {
          let list = res.data.data;
          this.authorMoreList = [];
          list.forEach(
            (item) => item.novel_id != id && this.authorMoreList.push(item)
          );
        }
      });
    },
    //最新章节列表
    getNewChapterList() {
      fictionChapterList(1, -1, this.novel_id).then((res) => {
        if (res.code == 200) {
          this.newChapterList = res.data.data;
        }
      });
    },
    // 获取小说章节列表接口
    getFictionChapterList() {
      fictionChapterList(this.currentPage, 50, this.novel_id).then((res) => {
        if (res.code == 200) {
          this.chapterList = res.data.data;
          this.total = res.data.total || 0;
          // this.endPage = res.data.last_page;
          this.endPage = this.total > 0 ? Math.ceil(this.total / 50) : 0;
        }
      });
    },

    //热门小说
    getHotList() {
      fictionHot(1, 10, 0).then((res) => {
        if (res.code == 200) {
          this.hotList = res.data.data;
        }
      });
    },
    // 去分类列表
    goTypeList(key, type, name) {
      if (type == 3) {
        key = key.replace(/\s/g, "-");
        this.$router.push({ path: `/author/${key}` });
      }
      if (type == 2) {
        name = name.replace(/\s/g, "-");
        this.$router.push({
          path: `/genre/${key}`,
          query: { type: name },
        });
      }
      if (type == 4) {
        this.$router.push({ path: `/status/${key}` });
      }
      // let obj = { key: key, type: type, name: name };
      // this.$store.commit("set_bookInfo", obj);
      // console.log("去分类列表", key, type,name);
    },
    // 去小说详情
    goChapterList(id) {
      if (this.novel_id !== id) {
        // this.novel_id = id;
        this.$router.push({
          path: `/articleList/${id}`,
        });
        this.reload();
        // this.getFictionDetail();
      }
    },
    // 关闭选择框
    closeSelect() {
      this.$refs.nav.clearSelect();
    },
    // 去小说章节
    goChapter(nid, cid, name) {
      let arrayLocal = JSON.parse(localStorage.getItem("historyList"));
      if (arrayLocal) {
        let isTrue = arrayLocal.some((item) => item.novel_id == nid);
        if (isTrue) {
          arrayLocal.forEach((item) => {
            if (item.novel_id == nid) {
              item.last_chapter_name = name;
              item.chapter_id = cid;
            }
          });
        } else {
          arrayLocal.unshift({
            novel_id: nid,
            novel_name: this.content.novel_name,
            last_chapter_name: name,
            chapter_id: cid,
          });
        }
      } else {
        arrayLocal = [];
        arrayLocal.unshift({
          novel_id: nid,
          novel_name: this.content.novel_name,
          last_chapter_name: name,
          chapter_id: cid,
        });
      }

      localStorage.setItem("historyList", JSON.stringify(arrayLocal));
      this.$router.push({
        path: `/article/${nid}/${cid}`,
      });
    },
    // 切换页码
    changePage(value) {
      if (value == 1) {
        this.goFirst();
      } else if (value == this.endPage) {
        this.goEnd();
      } else {
        this.currentPage = value;
        this.getFictionChapterList();
      }
    },
    // 去首页
    goFirst() {
      this.currentPage = 1;
      this.getFictionChapterList();
    },

    // 去最后一页
    goEnd() {
      this.currentPage = this.endPage;
      this.getFictionChapterList();
    },
    // 跳转到第几页
    goPage(value) {
      if (Number(value) >= this.endPage) {
        this.goEnd();
      }
      if (Number(value) <= 1) {
        this.goFirst();
      }
      if (Number(value) > 1 && Number(value) < this.endPage) {
        this.currentPage = Number(value);
        this.getFictionChapterList();
      }
      this.pageValue = "";
      this.pageShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.article_list {
  .content {
    display: flex;

    .content_l {
      flex: 1;

      .text_title {
        height: 40px;
        text-transform: uppercase;
        border-bottom: 1px solid #ccc;
        position: relative;
        font-size: 20px;
        margin-bottom: 15px;

        h2 {
          cursor: default;
          display: inline-block;
          height: 40px;
          line-height: 40px;
          border-bottom: 1px solid #4e4e4e;
        }
      }

      .books {
        display: flex;

        .books_l {
          width: 32%;

          .books_desc {
            margin: 15px 0 30px;
            box-shadow: 5px 5px 20px #333;
            position: relative;
            z-index: 2;
            width: 221px;

            img {
              vertical-align: middle;
              border: 1px solid #000;
              border-left: 3px solid #000;
              height: 324px;
              width: 221px;
            }

            &:before {
              position: absolute;
              width: 100%;
              left: 7.5%;
              background-color: #000;
              position: absolute;
              top: 2%;
              height: 96%;
              content: " ";
              z-index: -1;
            }

            &:after {
              width: 5%;
              left: 100%;
              background-color: #efefef;
              box-shadow: inset 0px 0px 5px #aaa;
              position: absolute;
              top: 2%;
              height: 96%;
              content: " ";
              z-index: -1;
            }
          }

          .books_info {
            padding: 5px;
            margin-top: 5px;
            margin-right: 15px;
            border-right: 1px solid #ddd;

            p {
              margin-bottom: 5px;
            }

            .hover_c:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }

        .books_r {
          width: 68%;
          flex: 1;
          padding-right: 15px;

          .books_r_t {
            text-align: center;
            font-weight: bold;
            margin-bottom: 5px;
            padding: 0 15px 7px;
            border-bottom: 2px solid #ddd;
            text-transform: uppercase;
          }

          .books_con {
            margin-top: 10px;
            max-height: 200px;
            overflow: hidden;

            &::v-deep p {
              margin-bottom: 20px;
            }
          }

          .books_btn {
            text-align: right;
            background-color: #f4f4f4;

            p {
              cursor: pointer;
              margin-top: 5px;
              display: inline-block;
              padding: 1px 5px;
              line-height: 1.5;
              border-radius: 3px;
              border: 1px solid #ccc;
            }
          }

          .max_h {
            max-height: 2000px;
          }
        }
      }

      .chapter {
        .chapter_t {
          text-transform: uppercase;
          font-family: "Roboto Condensed";
          height: 30px;
          width: 100%;
          margin: 15px 0 10px;
          border-bottom: 1px solid #ccc;

          h2,
          h3 {
            cursor: default;
            height: 30px;
            line-height: 30px;
            display: inline-block;
            border-bottom: 1px solid #4e4e4e;
          }
        }

        .chapter_list {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-wrap: wrap;
          max-height: 750px;
          overflow: hidden;

          .chapter_item {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 5px 15px;
            width: 50%;

            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }

        .chapter_c {
          p {
            cursor: pointer;
            padding: 5px 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .page_box {
        cursor: pointer;
        display: flex;
        margin: 30px 0 38px;
        justify-content: center;

        .first {
          line-height: 20px;
          border: 1px solid #ddd;
          border-right: none;
          padding: 4px 10px;
        }

        .end {
          line-height: 20px;
          border: 1px solid #ddd;
          padding: 4px 10px;
          border-left: none;
        }

        .page_class {
          &::v-deep .van-pagination__item {
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            height: 32px;
            width: 27px;
            padding: 5px 10px;
            color: #4e4e4e;
            background: #f2f2f2;
          }

          &::v-deep .van-pagination__item--active {
            background-color: #34495e;
            border-top: #34495e;
            border-bottom: #34495e;
            color: #fff;
          }

          &::v-deep .van-pagination__item--disabled,
          &::v-deep .van-pagination__item--disabled:active {
            opacity: 1;
            background: #ffffff !important;
          }

          &::v-deep .van-pagination__page {
            flex-grow: 1;
          }
        }

        .active_white {
          background-color: #fff;
        }

        .page-nav {
          text-align: center;
          position: relative;

          .page-btn {
            padding: 5px 10px;
            display: inline-block;
            border: 1px solid #ddd;

            .caret {
              display: inline-block;
              width: 0;
              height: 0;
              margin-left: 4px;
              vertical-align: middle;
              border-bottom: 4px dashed;
              border-bottom: 4px solid \9;
              border-right: 4px solid transparent;
              border-left: 4px solid transparent;
            }
          }

          .input-group {
            position: absolute;
            top: -36px;
            right: calc(50% - 50px);
            display: table;
            box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
            border-radius: 4px;

            .t_class {
              height: 34px;
              padding: 6px 12px;
              color: #555;
              border: 1px solid #ccc;
              border-radius: 4px;
              display: table-cell;
              box-sizing: border-box;
            }

            .form-control {
              max-width: 117px;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .input-group-btn {
              border-left: none;
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
            }
          }

          .active_b {
            background-color: #34495e;
            border-color: #34495e;
            color: #fff;
          }
        }
      }
    }

    .f_w {
      font-weight: bold;
    }

    .m_b {
      margin-bottom: 15px;
    }

    .content_r {
      width: 300px;
      margin-left: 7.5px;
    }
  }
}
</style>
